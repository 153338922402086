html {
	font-family: sans-serif;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%
}

body {
	margin: 0;
}

/**//**//**/
/* Обнуляем отступы и устанавливаем box-sizing */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Устанавливаем ширину и максимальную ширину */
html, body, #root, .App {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

/* Пример стилей для контейнера */
.container {
    padding: 20px;
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
}

/* Дополнительные стили для адаптивности */
img {
    max-width: 100%;
    height: auto;
}

/**//**//**/

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
	display: block
}

audio,
canvas,
progress,
video {
	display: inline-block
}

audio:not([controls]) {
	display: none;
	height: 0
}

progress {
	vertical-align: baseline
}

[hidden],
template {
	display: none
}

a {
	background-color: transparent;
	-webkit-text-decoration-skip: objects
}

a:active,
a:hover {
	outline-width: 0
}

abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted
}

b,
strong {
	font-weight: inherit
}

b,
strong {
	font-weight: bolder
}

dfn {
	font-style: italic
}

h1 {
	font-size: 2em;
	margin: .67em 0
}

mark {
	background-color: #ff0;
	color: #000
}

small {
	font-size: 80%
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline
}

sub {
	bottom: -.25em
}

sup {
	top: -.5em
}

img {
	border-style: none
}

svg:not(:root) {
	overflow: hidden
}

code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	font-size: 1em
}

figure {
	margin: 1em 40px
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible
}

button,
input,
select,
textarea {
	font: inherit;
	margin: 0
}

optgroup {
	font-weight: 700
}

button,
input {
	overflow: visible
}

button,
select {
	text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
	-webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
	border-style: none;
	padding: 0
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
	outline: 1px dotted ButtonText
}

.feature-list {
    display: flex;
    flex-wrap: wrap;
}

.feature-list h3{
	font-weight: bold;
	color: grey;
}

.feature-item {
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
}

.icon {
    width: 50px;
    height: 50px;
    margin: 0 auto; /* Центрирование по горизонтали */
    display: flex !important; /* Создание гибкого контейнера */
	margin-bottom: 10px;
}

.icon img {
    max-width: 100%;
    max-height: 100%;
}

.content h3 {
    margin-top: 0;
    margin-bottom: 10px;
}

.content p {
    margin-top: 0;
	font-size: 16px;
}

.content-about p {
    margin-top: 0;
	font-size: 19px;
}
.content-about h3 {
	margin-bottom: 5px;
	text-align:center;
}

@media (max-width: 767px) {
    .content-about p {
        font-size: 1em; /* Используем относительные единицы для размера шрифта */
        text-align:justify; /* Выравниваем текст по ширине */
        padding: 3px; /* Добавляем отступы вокруг текста */
        margin-bottom: 10px; /* Добавляем отступ снизу */
        line-height: 1.5; /* Задаем интервал между строками */
        word-spacing: 1px; /* Настройка интервала между словами */
        letter-spacing: 0.05em; /* Настройка интервала между буквами */
        max-width: 100%; /* Ширина блока равна ширине родительского контейнера */
    }

	.feature-item {
		width: 50%;
		padding: 10px;
	}
}

fieldset {
	border: 1px solid silver;
	margin: 0 2px;
	padding: .35em .625em .75em
}

legend {
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal
}

textarea {
	overflow: auto
}

[type=checkbox],
[type=radio] {
	box-sizing: border-box;
	padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
	height: auto
}

[type=search] {
	-webkit-appearance: textfield;
	outline-offset: -2px
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
	-webkit-appearance: none
}

::-webkit-input-placeholder {
	color: inherit;
	opacity: .54
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit
}

.visually-hidden {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px
}

* {
	-webkit-font-smoothing: antialiased;
	box-sizing: border-box;
	font-weight: 400
}

:focus {
	outline: 2px solid #ff6900
}

body {
	font-family: OpenSans-Regular;
	color: #000
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
	margin: 0
}

ul {
	list-style: none;
	padding: 0;
	margin: 0
}

a {
	color: inherit;
	-webkit-tap-highlight-color: transparent;
	-webkit-tap-highlight-color: transparent
}

input {
	border: none
}

.container {
	margin: 0 auto;
	padding-left: 20px;
	padding-right: 20px;
	max-width: 1220px
}

.support_container {
	font-family: "Raleway";
	color: grey;
	margin: 0 auto;
	margin-top: 50px; 
	max-width: 1220px
}

.icon {
	display: inline-block;
	line-height: .8
}

.icon>svg {
	display: inline-block;
	font-size: inherit;
	width: 1em;
	height: 1em;
	overflow: visible;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor
}

.action-button {
	position: relative;
	overflow: hidden;
	border: none;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 auto;
	cursor: pointer;
	background-image: linear-gradient(to bottom, #ff9300, #ff6900)
}

.action-button__text {
	position: relative;
	font-size: 16px;
	font-family: OpenSans-Bold;
    font-weight: bold;
	text-transform: uppercase;
	color: #fff
}

.action-button__icon {
	margin-left: 10px;
	position: relative;
	width: 30px;
	height: 30px;
	display: none
}

.form_loading .action-button__icon {
	display: block
}

.action-button .icon {
	margin-left: 20px;
	position: relative;
	font-size: 34px
}

.gift-card-banner {
	background-color: #f8f8f8;
	padding: 10px 0
}

.gift-card-banner__container {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: relative
}

.gift-card-banner__content {
	padding-top: 10px;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center
}

.gift-card-banner__icon {
	font-size: 38px;
	line-height: .6;
	color: #fff;
	margin-left: -10px;
	color: #3067aa
}

.gift-card-banner__text {
	color: #fff;
	margin-left: 8px;
	font-size: 14px;
	color: #3067aa;
	font-family: OpenSans-Bold
}

.gift-card-banner__text strong {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 400;
	font-family: OpenSans-Bold;
	display: block;
	color: #ff4801
}

.gift-card-banner__text a {
	font-family: OpenSans-Bold;
	display: block;
	color: #363636
}

.gift-card-banner__close {
	background-color: transparent;
	color: #363636;
	font-size: 18px;
	line-height: .6;
	position: absolute;
	top: 2px;
	right: 2px;
	border: 0;
	padding: 0;
	cursor: pointer
}


/*Новое меню / лого / */
.custom-header {
	display: flex;
	align-items: center; 
	/* justify-content: space-between;  */
	padding: 30px 20px;
	/* position: fixed; */
	top: 0;
	z-index: 1000;
	background-color:#ffffff;
  }
  
  .custom-header__logo img {
	width: 150px;
	height: auto;
  }

  .custom-header__logo a {
	margin-left: 120px;
}

  .custom-header__menu {
	margin-right: 30px;
  }
  
 /* Общие стили для контейнера кнопок */
 .button-container {
	margin-right: -90px;
	
	}
	.button {
	margin-right: 290px;
	display: block; /* Изменено на display: block; */
	margin-bottom: 20px;
	padding: 15px 30px 10px 15px;
	text-align: center;
	text-decoration: none;
	border-radius: 5px;
	transition: background-color 0.3s ease;
	color: #fff;
	font-family: "Raleway";
	line-height: 1;
	font-weight: bold;
	font-feature-settings: 'lnum' 1;
	}
	/* Стили для кнопки телефона */
	.phone-button {
	background-color: #ff6900;
	color: white;
	font-size: 19px;
	}
	/* Стили для кнопки смс */
	.sms-button {
	background-color: #0090ea;
	color: white;
	font-size: 22px;
	}
	/* Эффект при наведении для обеих кнопок */
	.button:hover {
	background-color: orange;
	}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 1678px) {
	body {
	/* Добавляет отступ сверху для компенсации фиксированного header */
	  padding-top: 180px; 
	  width: 100%;
	}
  
	.custom-header {
		/* Фиксирует header только на мобильных устройствах */
	  position: fixed; 
	  top: 0;
	  width: 100%;
	  flex-direction: column;
	  /* align-items: flex-start; */
	  padding: 10px 20px;
	  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Добавляет тень для визуального разделения */
	}
  
	.custom-header__logo img {
	  margin-left: 14px;
	  width: 22%;
	  vertical-align: bottom;
	}

	.custom-header__logo a {
		text-decoration: none;
		margin-left:0%
	  }

	.logo-text_1 {
		margin-left: 24px;
		text-decoration: none;
		color: #0090ea;
		font-family: "Raleway";
		font-size: 55px;
		font-optical-sizing: auto;
		font-weight: 900;
  		font-style: normal;
		text-decoration: none; /* Remove underline from the text */
	  }

	  .logo-text_2 {
		text-decoration: none;
		color: orange;
		font-family: "Raleway";
		font-size: 55px;
		font-optical-sizing: auto;
		font-weight: 900;
  		font-style: normal;
		text-decoration: none; /* Remove underline from the text */
	  }

	.custom-header__menu {
	  margin: 10px 0;
	  width: 100%;
	  display: flex;
	  justify-content: center;
	}
  
	.button-container {
		display: flex;
		flex-direction: row; /* Располагает кнопки горизонтально */
		width: 100%;
		justify-content: center; /* Центрирует кнопки по горизонтали */
		align-items: center; /* Центрирует кнопки по вертикали */
		margin-right: 0;
	  }
	
	  .button {
		margin: 0 10px; /* Добавляет отступы между кнопками */
		padding: 10px 15px;
		/* Не позволяет кнопкам растягиваться */
		flex: none; 
		box-sizing: border-box; /* Учитывает отступы и границы в ширине кнопок */
	  }
  
	.phone-button, .sms-button {
	  font-size: 20px;
	}

/* Отключаем эффект при наведении для обеих кнопок */
.phone-button:hover {
    background-color: #ff6900; /* Замените на желаемый цвет фона кнопки */
  }
  .sms-button:hover {
    background-color: #0090ea; /* Замените на желаемый цвет фона кнопки */
  }
  }

/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {


	.phone-button::after {
		content: attr(data-text-mobile); /* Изменяем текст кнопки для мобильных устройств */
	  }
  }
  
  
  
/* Скрываем элементы .logo-text_1 и .logo-text_2 на полном разрешении */
@media (min-width: 1678px) {
	.logo-text_1,
	.logo-text_2 {
	  display: none;
	}
  }

  /* Скрываем lgo*/
  @media (min-width: 650px) and (max-width: 1678px) {
	.custom-header__logo img {
	  display: none;
	}
  }
  

.header {
	background-color: #fff;
	padding: 20px 0
}

.header__container {
	/* width: 100%; */
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: 0 auto;
}



.header__home-url {
	display: block
}

.header__logo {
	height: auto;
	width: 150px;
    margin-left: -70px; /* Двигать лого */

}

/* Стили для мобильной версии */
@media screen and (max-width: 768px) {
    .header__container {
        -webkit-justify-content: center;
        justify-content: center;
    }
	.header__logo {
		margin-left: -20px
	}

}


.header__logo_google {
	display: block;
	height: auto;
	width: 10%;
    margin-left: 20%; /* Примерно 20% от ширины экрана */
}

.header__logo_yelp {
	display: block;
	height: auto;
	width: 10%;
    margin-left: 20%; /* Примерно 20% от ширины экрана */
}

.review-container {
	margin-top: 40px;
    display: flex;
    flex-wrap: nowrap; /* Отменяем перенос на следующую строку */
    overflow-x: auto; /* Горизонтальная прокрутка при нехватке места */
    gap: 20px; /* Отступ между отзывами */
    padding: 4px; /* Внутренний отступ для контейнера */
    justify-content: center;
    }
    .review {
    border: 1px solid #ccc;
    border-radius: 10px; /* Закругляем края квадратов */
    padding: 15px;
    width: 220px; /* Ширина отзыва */
    position: relative;
    }
    .review .author {
    font-weight: bold;
    margin-bottom: 5px;
    }
    .review .date {
    font-style: italic;
    color: #666;
    font-size: 0.9em;
    }
    .review .content {
    margin-top: 10px;
    }
    .stars {
    color: #FFD700;
    }
    .yelp-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 60px;
    }

	@media (max-width: 768px) {
		.review:nth-child(n+3) {
		  display: none;
		}
	  }

.phone {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	text-decoration: none;
    margin-right: 110px
}

.phone-container {
    display: flex;
    /* flex-direction: column; */
}

.phone-wrapper {
    margin-top: 10px; /* Регулируйте это значение по вашему усмотрению */
}

.phone-wrapper_message {
    margin-top: 10px; /* Регулируйте это значение по вашему усмотрению */
}

@media only screen and (max-width: 767px) {
    .phone-wrapper_message {
      display: none;
    }
  }

.phone__icon {
	background-color: #ff6900;
	color: #fff;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	font-size: 30px;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	display: none
}

.phone__content {
	margin-left: 9px
}

.phone__text {
	color: #000;
	font-size: 11px;
	font-family: OpenSans-Bold;
	text-align: center;
	text-transform: uppercase;
	display: block
}

.phone__number {
	font-size: 24px;
	font-family: OpenSans-Bold;
	line-height: 1;
	color: #0064cc;
	display: block
}

.banner__image {
	display: none;
	/* max-width: 668px; */
	width:15%;
	height: auto;
	position: absolute;
    margin-right: 200px;
	bottom: 110px;
	left: 390px;
}

@media only screen and (max-width: 767px) {
    .banner__image {
      display: none;
    }
  }

.banner__title {
	font-family: OpenSans-Bold;
	text-align: justify;
	padding: 15px 20px 25px;
    background-color: #ffffff;
	width: calc(100% + 40px);
	margin-left: -20px;
}

.banner__title span {
	display: block;
	line-height: 1;
	font-family: "Raleway";
	font-size: 34px;
}

.banner__title span:nth-child(1) {
	font-family: "Raleway";
	color: #0064cc;
	font-size: 38px;
	letter-spacing: -1.9px
}

.banner__title span:nth-child(2) {
	font-size: 24px;
	color: #0064cc;
	font-family: "Raleway";
}

.banner__title span:nth-child(3) {
	background-color: #ff9300;
	color: #fff;
	max-width: 255px;
	margin: 0 auto;
	padding: 5px 0 10px;
	border-radius: 50px;
	line-height: 1.2;
	font-size: 32px;
	margin-top: 10px
}

.banner__title span:nth-child(3) a {
	color: #fff;
	text-decoration: none;
	display: inline-block;
	line-height: 1;
	font-family: OpenSans-Regular;
	vertical-align: super;
	font-size: 19px
}

.banner_text_about_us {
	font-family: "Raleway";
	color: grey;
	font-size: 21px;
	font-weight: 400;
	font-optical-sizing: auto;
	font-style: normal;
}

.banner-quote {
	background-image: linear-gradient(to bottom, #0090ea, #0064cc);
	border-radius: 18px;
	/* border-top-left-radius: 0;
	border-top-right-radius: 0; */
	position: relative;
	z-index: 10;
	padding: 22px 20px 28px;
	max-width: 472px;
	margin-left: auto;
	margin-right: auto
}

.banner-quote__h1 {
	text-align: center;
	color: #fff;
	line-height: .92;
	font-size: 20px;
	font-family: OpenSans-Bold;
    font-weight: bold;
	margin-bottom: 15px;
	text-transform: uppercase;
}

.banner-form {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}

.banner-form__text {
	color: #fff;
	font-size: 13px;
	line-height: 1.3
}

.banner-form__text a {
	text-decoration: underline;
	font-family: OpenSans-Bold;
	font-family: OpenSans-Bold
}

.banner-form__text p {
	margin: 5px 0 0 0
}

.banner-form__text strong {
	font-family: OpenSans-Bold
}

.banner-form__item {
	font-family: "Raleway";
	margin-bottom: 8px;
	display: block;
	width: 100%;
}

.banner-form__item:last-of-type {
	margin-bottom: 0
}

.banner-form__item_half {
	width: calc(50% - 9px)
}

.banner-form__item_radio {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 12px
}

.banner-form__label {
	color: #fff;
	font-size: 14px;
	display: inline-block;
}

.banner-form__label_radio {
	width: 30%
}

.banner-form__input {
	display: block;
	width: 100%;
	height: 40px;
	padding: 5px 20px;
	background-color: #fff;
	border-radius: 8px;
	font-size: 21px;
	line-height: 1;
	font-feature-settings: 'lnum' 1;
}

.banner-form__input {
	color: #585858
}

.banner-form__input::-webkit-input-placeholder {
	color: #585858;
	opacity: 1
}

.banner-form__input:-moz-placeholder {
	color: #585858;
	opacity: 1
}

.banner-form__input::-moz-placeholder {
	color: #585858;
	opacity: 1
}

.banner-form__input:-ms-input-placeholder {
	color: #585858;
	opacity: 1
}

.banner-form .radio {
	margin-right: 30px
}

.banner-form .radio:last-child {
	margin-right: 0
}

.banner-form .radio:focus-within {
	outline: 2px solid #ff6900
}

.banner-form .radio__options {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 70%
}

.banner-form .radio__label {
	color: #fff;
	font-size: 14px;
	position: relative;
	padding-left: 30px;
	line-height: 1;
	display: inline-block
}

.banner-form .radio__label::before {
	content: '';
	position: absolute;
	display: block;
	left: 0;
	top: -2px;
	width: 18px;
	height: 18px;
	background-color: #fff;
	border-radius: 50%;
	box-sizing: border-box
}

.banner-form .radio__label::after {
	content: '';
	display: none;
	position: absolute;
	left: 5px;
	top: 3px;
	width: 8px;
	height: 8px;
	background-image: linear-gradient(to bottom, #ff9400, #ff6a00);
	border-radius: 50%;
	box-sizing: border-box
}

.banner-form .radio__input:checked+.radio__label::after {
	display: block
}

.banner-form .checkbox:focus-within {
	outline: 2px solid #ff6900
}

.banner-form .checkbox__label {
	color: #fff;
	font-size: 12px;
	position: relative;
	padding-left: 30px;
	line-height: 1;
	display: inline-block
}

.banner-form .checkbox__label::before {
	content: '';
	position: absolute;
	display: block;
	left: 0;
	top: -2px;
	width: 18px;
	height: 18px;
	background-color: #fff;
	border-radius: 4px;
	box-sizing: border-box;
	background-position: center center;
	background-repeat: no-repeat
}

.banner-form .checkbox__input:checked+.checkbox__label::before {
	background-color: #47c796;
}

.banner-form label.form__error {
	background-color: #eb0000;
	display: inline-block;
	font-size: 12px;
	color: #fff;
	padding: 2px 6px;
	margin-top: 4px;
	border-radius: 2px
}

.banner-form .form__error {
	border: 2px solid #eb0000
}

.banner-form__controls {
	margin-top: 12px;
	width: 100%
}

.banner-form__submit {
	height: 50px;
	max-width: 240px;
	width: 100%;
	text-align: center;
	border-radius: 30px;
	color: #fff;
	font-size: 16px;
	font-family: OpenSans-Bold;
	text-transform: uppercase
}

.banner-rs {
	margin-top: 24px
}

.banner-rs__container {
	max-width: 1010px;
	background-color: #f5fcff;
	padding: 30px 20px
}

.banner-rs__time {
	text-align: center;
	color: #f08100;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center
}

.banner-rs__time .icon {
	margin-right: 10px;
	line-height: .6;
	font-size: 32px
}

.banner-rs__time p {
	text-transform: uppercase;
	font-family: OpenSans-Bold;
	margin: 0;
	font-size: 15px
}

.banner-rs__title {
	color: #004ebb;
	text-transform: uppercase;
	text-align: center;
	margin-top: 5px;
	font-family: OpenSans-Regular;
	font-size: 24px
}

.banner-rs__title strong {
	font-family: OpenSans-Bold
}

.banner-rs__text {
	text-align: center;
	color: #004ebb;
	font-size: 18px
}

.banner-rs__text p {
	margin: 0
}

.features {
	padding-bottom: 35px
}

.features__container {
	max-width: 500px	
}

.features__h1 {
	text-align: center;
	font-family: "Raleway", sans-serif;
    font-weight: bold;
	font-size: 22px;
	line-height: 1.2;
	color: #0064cc
}

.features__list-container {
	margin: 25px auto 0;
}

.features__list {
	margin-bottom: 18px
}

.features__list:last-child {
	margin-bottom: 0
}

.features__item {
	padding-left: 43px;
	padding-right: 10px;
	margin-bottom: 18px;
	background-image: url(../assets/image/check.webp);
	background-position: left 2px;
	background-repeat: no-repeat;
    background-size: 35px 35px;
}

@media screen and (max-width: 768px) {
    .features__item {
        background-image: none;
		padding-left: 10px;
		padding-right: 10px;
		text-align: justify;
    }
}

.features__item:last-child {
	margin-bottom: 0
}

.div-contact-text-centr {
	display: flex;
	justify-content: center; /* Выравнивание по горизонтали */
	align-items: center; /* Выравнивание по вертикали */
	height: 100vh; /* Высота равная высоте видимой части окна браузера */
  }

.features__text {
	text-align:left;
	font-family: "Raleway", sans-serif;
	color: grey;
	margin: 0;
	font-size: 16px;
	line-height: 1.36
}

.features__text_header {
	font-family: "Raleway", sans-serif;
	color: grey;
	margin: 0;
	font-size: 19px;
	line-height: 1.36;
	font-weight: bold;
}

.tv_mounting_skills_list {
	display: flex;
	justify-content: left;
	flex-wrap: wrap;
	list-style: none; /* Remove default list styling */
	padding: 0; /* Remove default padding */
	margin: 0; /* Remove default margin */
  }
  
  .tv_mounting_skills {
	font-family: "Raleway", sans-serif;
	color: black !important;
	font-size: 24px !important;
	line-height: 1.36;
	font-weight: bold;
	display: flex;
	align-items: center;
	padding: 10px 0; /* Adjust padding as needed */
	flex: 1 1 auto; /* Allow items to grow and shrink as needed */
	/* margin-right: 20px; */
	text-transform: uppercase;
	font-feature-settings: 'lnum' 1;
	/* margin: 10px; */
	flex-basis: 36%; /* Установить ширину элемента в половину контейнера */
	text-align: left !important;
  }
  
  .tv_mounting_skills:last-child {
	margin-right: 0; /* Remove margin on the last item */
  }
  
  .tv_mounting_skills::before {
	content: '';
	display: inline-block;
	width: 25px; /* Set the width of your image */
	height: 25px; /* Set the height of your image */
	margin-right: 5px; /* Space between the image and text */
	background-image: url('../assets/image/check.webp'); /* Replace with the path to your image */
	background-size:cover; /* Ensures the image covers the entire element */
  }



  .furniture_skills_list {
	display: flex;
	justify-content: left;
	flex-wrap: wrap;
	list-style: none; /* Remove default list styling */
	padding: 0; /* Remove default padding */
	margin: 0; /* Remove default margin */
	margin-bottom: 15px;


	padding-left: 0; /* Убираем отступ слева */
  }
  
  .furniture_skills {
	font-family: "Raleway", sans-serif;
	color:grey;
	font-size: 21px;
	line-height: 1.36;
	font-weight: bold;
	display: flex;
	align-items: center;
	padding: 10px 0; /* Adjust padding as needed */
	flex: 1 1 auto; /* Allow items to grow and shrink as needed */
	/* margin-right: 20px; */
	text-transform: uppercase;
	font-feature-settings: 'lnum' 1;
	/* margin: 10px; */
	flex-basis: 36%; /* Установить ширину элемента в половину контейнера */
	text-align: left !important;
	position: relative;

	padding-left: 40px; /* Отступ слева для текста, учитывая ширину иконки */
	margin-bottom: 10px; /* Отступ между элементами списка */
  }
  
  .furniture_skills:last-child {
	margin-right: 0; /* Remove margin on the last item */
  }
  
  .furniture_skills::before {
	content: '';
	position: absolute;
	left: 0;
	display: inline-block;
	width: 30px; /* Set the width of your image */
	height: 30px; /* Set the height of your image */
	margin-right: 10px; /* Space between the image and text */
	background-image: url('../assets/image/check.webp'); /* Replace with the path to your image */
	background-size: cover; /* Ensures the image covers the entire element */
  }
  
  @media (max-width: 768px) {
	.tv_mounting_skills_list {
	  flex-direction: column; /* Stack items vertically on small screens */
	}
	
	.tv_mounting_skills {
	  margin-right: 0; /* Remove horizontal margin in column layout */
	  margin-bottom: 5px !important; /* Add space between items */
	  font-size: 16px !important;
	}
  
	.tv_mounting_skills:last-child {
	  margin-bottom: 0; /* Remove margin on the last item */
	}

	.furniture_skills_list {
		flex-direction: column; /* Stack items vertically on small screens */
	  }
	  
	  .furniture_skills {
		margin-right: 0px !important; /* Remove horizontal margin in column layout */
		margin-bottom: 1px; /* Add space between items */
		font-size: 16px;
	  }
	
	  .furniture_skills:last-child {
		margin-bottom: 0; /* Remove margin on the last item */
	  }
  }
  

.about {
	padding-bottom: 15px
}

.about__container {
	margin-top: 10px;
	max-width: 500px
}

.about__h1 {
	text-align: center;
	font-family: "Raleway";
    font-weight: bold;
	font-size: 22px;
	line-height: 1.2;
	color: #0064cc
}

.about__p {
	font-family: "Raleway";
	text-align: justify;
	font-size: 16px;
	max-width: 970px;
	line-height: 1.37;
	color: grey;
	margin: 0 auto;

	/* margin: 5 10px; */
	/* margin: 20px auto 0; */
}

.testimony {
	/* padding-top: 25px;
	padding-bottom: 25px */
}

.testimony__container {
	max-width: 500px
}

.testimony__p {
	font-family: "Raleway";
	font-style: italic;
	text-align: center;
	color: #0064cc;
	font-style: italic;
	margin: 0 auto;
	font-size: 18px;
	max-width: 1010px;
	line-height: 1.33;
	padding: 45px 20px;
	background-color: #eaf1f7;
	border-radius: 40px;
	border-top-left-radius: 0;
	border-bottom-right-radius: 0
}

.get-quote__container {
	padding-top: 30px;
	padding-bottom: 35px;
	background-image: linear-gradient(to bottom, #0090ea, #0064cc);
	border-radius: 20px; /* Установка радиуса закругления */
}

.get-quote__p {
	font-family: "Raleway", sans-serif;
	font-size: 18px;
	color: #fff;
	text-align: center;
	line-height: 1.33
}

@media screen and (max-width: 768px) {
    .get-quote__container  {
        display: none;
    }
}

@media screen and (min-width: 769px) {
    .mobile-only {
        display: none;
    }
}

.get-quote__link {
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	height: 50px;
	border-radius: 30px;
	color: #fff;
	font-family: OpenSans-Bold;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 18px;
	width: 100%;
    font-weight: bold;
}

.message {
	padding-top: 40px;
	padding-bottom: 40px;
	text-align: center;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 20px;
	min-height: calc(100vh - 260px)
}

.message__container {
	width: 100%
}

.message__image {
	height: auto;
	width: 100%;
	display: block;
	margin: 0 auto 20px
}

.message__image_ok {
	width: 107px
}

.message__title {
	line-height: 1.1;
	position: relative
}

.message__title-text_1 {
	font-family: OpenSans-Bold;
	font-size: 48px;
	display: block;
	color: #0064cc
}

.message__title-text_2 {
	font-family: OpenSans-Regular;
	color: #0064cc;
	font-size: 32px
}

.message__text {
	position: relative;
	margin: 15px auto 0;
	font-size: 18px;
	max-width: 80%;
	color: #000
}

.back-button {
	margin-top: 30px;
	height: 50px;
	max-width: 250px;
	border-radius: 35px;
	text-decoration: none
}

.back-button__text {
	position: relative;
	text-align: center;
	color: #fff;
	font-size: 16px;
	font-family: OpenSans-Bold;
	text-transform: uppercase
}

.back-button__icon {
	position: relative;
	color: #fff;
	top: 3px
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

@media only screen and (max-width: 768px) {
    .footer__block-title {
        display: none;
    }
    .footer__block-content {
        display: none;
    }
}

.container_tv {
	margin-bottom: 20px;
	overflow: hidden; /* Решает проблему с "всплыванием" float */
	}

	.container_tv p {		
		font-family: "Raleway", sans-serif;
		color: grey;
		line-height: 1.36;
		padding-left: 10px;
		padding-right: 10px;
		text-align: justify;
		margin-bottom: 20px;
    }

	.container_tv_qoute_form {
		display: flex;
		flex-direction: column;
		max-width: 470px;
		width: 100%;
		height: auto;
		margin: 0 auto;
		padding: 0 20px;
		float:inline-end;
		margin-left: 40px;
		}		

	.container_tv img {
	max-width: 300px;
	height: auto;
	float: left;
	margin-right: 20px; /* Добавляем отступ справа для разделения текста и изображения */
	}

	@media screen and (max-width: 767px) {
		.container_tv img {
			float:none;
			display: block;
			margin: 0 auto !important;
			}
		.text h3 {
			margin-top: 20px !important;
			font-size: 19px !important;
		}

		.image-container {
			flex-direction: row !important; /* Горизонтальное размещение */
			gap: 20px !important;

		  }
		  
		  .custom-image {
			width: 150px !important; /* Фиксированная ширина изображения */
			height: auto; /* Автоматическая высота для пропорций */
		  }

		  .image-caption{
			font-size: 14px !important;
		  }

		  .new-price{
			font-size: 18px !important;
		  }

		  .text-block-tv p{
			font-size: 14px !important;
		  }

	}

	.text h3 {
		font-family: "Raleway";
		font-style: normal;
		color: #0064cc;
	text-align: center; /* Выравниваем заголовки по центру */
	font-weight: bold;
	font-size: 22px;
	margin-bottom: 10px;
	}

.footer__blocks {
    display: flex;
    flex-wrap: wrap;
}

.footer__block {
    flex: 1 1 100%;
    margin-bottom: 20px; /* Добавляем отступ между блоками */
}

@media only screen and (min-width: 768px) {
    .footer__block {
        flex: 1 1 auto;
        margin-right: 20px; /* Добавляем отступ между блоками */
        margin-bottom: 0; /* Убираем отступ снизу */
    }
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


.footer {
    background-color: #f5f5f5;
    padding: 40px 0;
	font-family: "Raleway", sans-serif;
	font-weight: 100;
}

.footer__blocks {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer__block:last-child {
    margin-right: 0; /* Убираем правый отступ у последнего блока */
}

.footer__block {
    width: calc(25% - 20px);
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.footer__block-title {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
}

.footer__block-content {
    color: #666;
	text-align: justify;
	width: 220px;
}

.footer__block-list {
    list-style: none;
    padding: 0;
}

.footer__block-list li {
    margin-bottom: 5px;
    color: #666;
}

.footer__block-list li a {
    color: #666;
    text-decoration: none;
}

.footer__block-list li a:hover {
    text-decoration: underline;
}

.footer__disclaimer {
    font-size: 14px;
    color: #999;
    margin-top: 20px;
}

.footer__legal-text {
    font-size: 14px;
    text-align: center;
}


.footer__disclaimer {
	padding: 30px 0;
	margin: 0 auto;
	text-align: justify;
	font-size: 13px;
	line-height: 1.38;
	border-bottom: 1px solid #ccc
}

.footer__legal-info {
	text-align: center
}

.footer__legal-text {
    text-align: center;
    font-size: 12px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.footer__legal-links {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-bottom: 30px;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column
}

.footer__legal-link {
	font-size: 12px;
	padding: 0 14px;
	margin-bottom: 12px;
	color: #666;
	text-decoration: none
}

.footer__legal-link:last-child {
	border-right: none;
	margin-bottom: 0
}

.footer__legal-link:hover {
	text-decoration: underline
}


@media screen and (min-width:830px) {
	.action-button__text {
		font-size: 18px
	}

	.header__logo { /* Размер тут менять логотипа */
		width: 10%;
	}

	.phone__text {
		font-size: 15px
	}

	.phone__number {
		font-size: 34px
	}

	.banner__title span:nth-child(1) {
		font-size: 60px
	}

	.banner__title span:nth-child(2) {
		font-size: 40px
	}

	.banner__title span:nth-child(3) {
		font-size: 50px;
		max-width: 360px
	}

	.banner__title span:nth-child(3) a {
		font-size: 31px
	}

	.banner-quote__h1 {
		font-size: 30px;
	}

	.banner-rs {
		margin-top: 48px
	}

	.banner-rs__container {
		padding: 45px 20px;
		border-radius: 40px;
		border-top-left-radius: 0;
		border-bottom-right-radius: 0
	}

	.banner-rs__time .icon {
		font-size: 42px
	}

	.banner-rs__time p {
		font-size: 19px
	}

	.banner-rs__title {
		font-size: 32px
	}

	.banner-rs__text {
		font-size: 20px
	}

	.features {
		/* padding-top: 45px; */
		padding-bottom: 45px
	}

	.features__h1 {
		font-size: 32px
	}

	.about {
		padding-top: 40px;
		padding-bottom: 20px
	}

	.about__h1 {
		font-size: 32px
	}

	.about__p {
		margin-top: 25px;
		font-size: 16px;
		max-width: 70%
	}

	.get-quote__container {
		padding-top: 40px;
		padding-bottom: 40px
	}

	.get-quote__p {
		font-size: 24px
	}
	

	.message {
		min-height: calc(100vh - 307px)
	}

	.message__image_ok {
		width: 120px
	}

	.message__title {
		line-height: 1.3
	}

	.message__title-text_1 {
		font-size: 66px
	}

	.message__title-text_2 {
		font-size: 32px
	}

	.message__text {
		font-size: 20px;
		max-width: none
	}

	.footer__disclaimer {
		font-size: 14px;
		padding: 40px 20px
	}

	.footer__legal-info {
		padding-bottom: 20px;
		border-top: none
	}

	.footer__legal-links {
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		padding-bottom: 0
	}

	.footer__legal-link {
		margin-bottom: 0
	}

	.footer__legal-link:first-child {
		padding-left: 0
	}

	.footer__legal-link:last-child {
		padding-right: 0
	}
}

@media screen and (min-width:1000px) {
	.gift-card-banner {
		padding: 12px 0 15px
	}

	.gift-card-banner__content {
		padding-top: 0
	}

	.gift-card-banner__icon {
		font-size: 48px
	}

	.gift-card-banner__text {
		margin-left: 15px;
		font-size: 24px
	}

	.gift-card-banner__text strong {
		font-size: 24px;
		display: inline
	}

	.gift-card-banner__text a {
		display: inline
	}

	.gift-card-banner__close {
		font-size: 24px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%)
	}

	.header__logo {
		width: 10%;
	}

	.phone__icon {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex
	}

	.phone__text {
		font-size: 18px
	}

	.phone__number {
		font-size: 38px
	}

	.banner {
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 66px 0 90px
	}

	.banner__container {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap
	}

	.banner__column {
		width: calc(50% - 20px);
		position: relative
	}

	.banner__image {
		display: block
	}

	.banner__title {
		background-color: transparent;
		padding: 0
	}
	.banner-quote {
		padding: 30px 45px 40px;
		margin: 0;
		border-radius: 20px
	}

	.banner-quote__h1 {
		font-size: 32px
	}

	.banner-form__item {
		margin-bottom: 30px
	}

	.banner-form__item_50d {
		width: calc(50% - 9px)
	}

	.banner-form__item_radio {
		margin-top: 10px
	}

	.banner-form__label {
		font-size: 16px
	}

	.banner-form .radio {
		margin-right: 15px
	}

	.banner-form .radio {
		margin-right: 25px
	}

	.banner-form .radio__label {
		padding-left: 25px
	}

	.banner-form .radio__label {
		font-size: 14px
	}

	.banner-form .checkbox__label {
		padding-left: 25px
	}

	.banner-form__submit {
		height: 50px;
		font-size: 18px;
		max-width: 260px
	}

	.banner-rs__time .icon {
		font-size: 48px
	}

	.banner-rs__time p {
		font-size: 20px
	}

	.banner-rs__title {
		font-size: 37px
	}

	.banner-rs__text {
		font-size: 20px
	}

	.features {
		padding-bottom: 75px
	}

	.features__container {
		max-width: 1200px
	}

	.features__h1 {
		font-size: 38px
	}

	.features__h1 br {
		display: none
	}

	.features__list-container {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		max-width: none;
		margin-top: 45px
	}

	.features__list {
		width: calc(50% - 30px)
	}

	.features__text {
		font-size: 19px
	}

	.about {
		padding-top: 55px;
		padding-bottom: 25px
	}

	.about__container {
		max-width: 1220px
	}

	.about__h1 {
		font-size: 38px;
	}

	.about__h1 br {
		display: none
	}

	.about__p {
		font-size: 19px
	}

	.testimony {
		padding-top: 35px
	}

	.testimony__container {
		max-width: 1220px
	}

	.testimony__p {
		padding: 74px 170px;
		font-size: 26px
	}

	.get-quote__p {
		font-size: 28px;
		width: 100%
	}

	.message__title-text_1 {
		font-size: 75px
	}

	.message__title-text_2 {
		font-size: 32px
	}

	.message__text {
		font-size: 22px
	}

	.footer {
		background-color: #eaf1f7;
		margin-top: 83px
	}

	.footer__legal-info {
		padding: 40px 0 45px;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center
	}

	.footer__legal-text {
		text-align: center;
		font-size: 14px;
		margin: 0
	}

	.footer__legal-link {
		font-size: 14px
	}
}

@media screen and (min-width:700px) {
	.gift-card-banner__icon {
		font-size: 43px
	}

	.gift-card-banner__text {
		font-size: 19px
	}

	.gift-card-banner__text strong {
		font-size: 19px
	}

	.header {
		padding: 12px 0 6px
	}

	.header__logo {
		width: 15%;
	}

	.phone__text {
		font-size: 13px
	}

	.phone__number {
		font-size: 29px
	}

	.banner__title span:nth-child(1) {
		font-size: 49px
	}

	.banner__title span:nth-child(2) {
		font-size: 32px
	}

	.banner__title span:nth-child(3) {
		font-size: 41px;
		max-width: 300px
	}

	.banner__title span:nth-child(3) a {
		font-size: 25px
	}

	.banner-quote__h1 {
		font-size: 27px
	}

	.banner-rs__time .icon {
		font-size: 37px
	}

	.banner-rs__time p {
		font-size: 17px
	}

	.banner-rs__title {
		font-size: 28px
	}

	.banner-rs__text {
		font-size: 19px;
		margin-top: 10px
	}

	.features__h1 {
		font-size: 27px
	}

	.about__h1 {
		font-size: 27px
	}

	.get-quote__p {
		font-size: 28px;
        font-weight: bold;
	}

	.message__title-text_1 {
		font-size: 57px
	}

	.message__title-text_2 {
		font-size: 32px
	}

	.message__text {
		font-size: 19px
	}
}

@media screen and (min-width:1220px) {
	.banner__title span:nth-child(1) {
		font-size: 72px
	}

	.banner__title span:nth-child(2) {
		font-size: 48px
	}

	.banner__title span:nth-child(3) {
		font-size: 58px;
		max-width: 430px
	}

	.banner__title span:nth-child(3) a {
		font-size: 38px
	}

	.get-quote__container {
		padding-top: 50px;
		padding-bottom: 50px;
		border-radius: 120px
	}
}

@media screen and (max-width:1000px) {
	.banner-form__item_50m {
		width: calc(50% - 9px)
	}
}

.menu {
    /* background-color: #eaf1f7; */
	/* Внутренний отступ сверху и снизу */
    /* padding: 10px 0; 
	margin: 0 auto;
	padding-left: 20px;
	padding-right: 20px;
	max-width: 900px; */
	/* Закругляем углы заднего фона */
	/* border-radius: 10px;  */
}

.menu__list {
    list-style-type: none; /* Убираем стандартное маркирование */
    margin: 0; /* Убираем внешний отступ */
    padding: 0; /* Убираем внутренний отступ */
	text-align: center;
}

.menu__item {
    display: inline; /* Делаем элементы горизонтальными */
}

.menu__link {
    text-decoration: none; /* Убираем подчеркивание ссылок */
	color: black; /* Цвет текста ссылок */
    font-size: 16px;
    font-weight: bold; /* Жирный шрифт */
	font-family: "Raleway";
	text-transform: uppercase;
}

.menu__link:hover {
    color: beige; /* Цвет текста при наведении */
}

@media screen and (max-width: 600px) {
    .menu {
        display: none; /* Скрываем меню при ширине экрана меньше 600px */
    }
}

.menu li a {
    text-decoration: none; /* Убираем подчеркивание ссылок */
    display: inline-block; /* Делаем элементы блочными */
    padding: 10px 20px; /* Добавляем внутренние отступы для кнопок */
    border: 2px solid transparent;
    border-radius: 5px; /* Добавляем закругление углов */
    transition: border-color 0.3s; /* Добавляем плавный переход для изменения цвета границы */
}

.menu li a:hover {
	background-color: #ff6900;
}

/* ---- answers
/* Center the block horizontally */
.accordion {
    margin: 20px auto;
    max-width: 950px; /* Limit the block width */
}

@media (max-width: 1000px) {
    .accordion {
        width: calc(100% - 40px); /* ширина = 100% - 20px отступ слева и 20px отступ справа */
        margin-left: 20px; /* левый отступ */
        margin-right: 20px; /* правый отступ */
    }
}

/* Styling for each accordion item */
.accordion__item {
    font-family: "Raleway";
    border-bottom: 1px solid #ccc;
    background-color: #ffffff; /* Change background to white */
}

/* Styling for the accordion title */
.accordion__title {
    font-family: "Raleway";
    font-weight: bold;
    position: relative;
    font-size: 22px;
    color: #0064cc;
    font-weight: bold;
    padding: 15px;
    cursor: pointer;
}

/* Styling for the plus icon */
.plus-icon {
    font-weight: 400;
    position: absolute;
    top: 10%;
    right: -3px;
    transform: rotate(0deg); /* Rotate the plus by 0 degrees */
    transition: transform 0.3s ease;
    color: #0064cc; /* Initial color of the plus */
    font-size: 44px;
    line-height: 1;
}

/* Change color of plus icon on hover */
.accordion__title:hover .plus-icon {
    color: orange;
}

/* Create circle around plus icon on hover */
.plus-icon:hover::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 24px; /* Plus width */
    height: 24px; /* Plus height */
    border-radius: 50%;
    border: 2px solid orange; /* Border color on hover */
}

/* Rotate plus icon when active */
.accordion__title.active .plus-icon {
    transform: rotate(45deg); /* Rotate plus by 45 degrees */
}

/* Styling for the accordion content */
.accordion__content {
    font-size: 18px;
    color: grey;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease; /* Increase animation duration */
    padding: 0px 15px; /* Add top and bottom padding */
    margin-bottom: 15px; /* Add bottom margin */
}

/* Set max height of active content */
.accordion__content.active {
    max-height: 2000px; /* Max height when open */
}

.review {
	/* Основные стили блока */
	padding: 20px;
	border-radius: 8px;
	background-color: #f9f9f9;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	transition: box-shadow 0.3s ease;
  }
  
  .review:hover {
	/* Стили при наведении курсора мыши */
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }  



/* ImageSlider */
.image-slider {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	position: relative;
  }
  
  .slick-prev, .slick-next {
	width: 30px;
	height: 30px;
	/* background-color: rgba(0, 0, 0, 0.5); */
	/* border-radius: 50%; */
	z-index: 1;
	/* cursor: pointer; */
  }
  
  .slick-prev:before, .slick-next:before {
	font-family: 'slick';
	font-size: 50px;
	color: white;
  }
  
  .slick-prev {
	left: 0; /* Положение стрелки "назад" от левого края */
  }
  
  .slick-next {
	right: 0; /* Положение стрелки "вперед" от правого края */
  }

  .slick-prev-slider-tv, .slick-next-slider-tv {
	width: 30px;
	height: 30px;
	/* background-color: rgba(0, 0, 0, 0.5); */
	/* border-radius: 50%; */
	z-index: 1;
	/* cursor: pointer; */
  }
  
  .slick-prev-slider-tv:before, .slick-next-slider-tv:before {
	font-family: 'slick';
	font-size: 50px;
	color: white;
  }
  
  .slick-prev-slider-tv {
	left: 0; /* Положение стрелки "назад" от левого края */
  }
  
  .slick-next-slider-tv {
	right: 0; /* Положение стрелки "вперед" от правого края */
  }

  @media (max-width: 768px) {
	.slick-prev-slider-tv {
		left: 40px !important; /* Положение стрелки "назад" от левого края */
	  }
	  
	  .slick-next-slider-tv {
		right: 60px !important; /* Положение стрелки "вперед" от правого края */
	  }
}
  
  .image-slider img {
	max-width: 80%;
	height: auto;
	border-radius: 10px;
	object-fit: cover;
	display: block;
	margin: 0 auto;
	outline: none;
  }

  .image-slider *:focus {
    outline: none; /* Убираем стандартный фокус для всех элементов внутри слайдера */
}

  .slider-container{
	margin-top: 20px; /* Отступ сверху */
  }

  .slick-dots li button:before{
	font-size: 10px;
  }

 .furniture-assembly-tools{
	font-family: "Raleway";
	font-size: 20px;
	color: grey;
	margin-top: 50px; 
	max-width: 1220px;
	margin: 0 auto;
 } 

 .furniture-assembly-tools h2{
	font-weight: bold;
	color: #0064cc;
 } 

 .furniture-assembly-tools h3{
	font-weight: bold;
 } 

 .furniture-assembly-tools p{
	margin-top: 10px;
	margin-bottom: 15px;
 } 
 
 .burger-menu-icon {
	cursor: pointer;
	font-size: 34px;
	z-index: 20;
	position: absolute;
	top: 20px;
	right: 20px;
  }

  /* Медиазапрос для мобильных устройств */
@media (max-width: 768px) {
	.burger-menu-icon {
		font-size: 32px;
		top: 14px;
		right: 14px;
	}
}

@media (max-width: 480px) {
	.burger-menu-icon {
		font-size: 28px;
		top: 8px;
		right: 8px;
	}
}
  
  .burger-menu-nav {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	position: absolute;
	top: 100%; /* Positioned below the header */
	left: 0;
	z-index: 10;
  }
  
  .burger-menu-list {
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
  }
  
  .burger-menu-item {
	padding: 10px 0;
	width: 100%;
	text-align: center;
  }
  
  .burger-menu-link {
	text-decoration: none;
	color: rgb(53, 52, 52);
	display: block;
	width: 100%;


    font-size: 18px; /* Размер шрифта */
    font-weight: bold; /* Жирный шрифт */
	font-family: "Raleway";
	text-transform: uppercase;
  }
  
  @media (max-width: 1678px) {
	.burger-menu-icon {
	  display: block;
	}
  
	body.menu-open {
	  padding-top: 200px; /* Adjust this value according to the height of your menu */
	}
  }
  


/* Styles for the table */
.table-container {
	width: 100%;
	max-width: 480px;
	margin: 0 20px;
	border-collapse: collapse;
	font-family: "Raleway";
  }
  
  .table-container th, .table-container td {
	border: 1px solid #ddd;
	padding: 12px;
	text-align: left;
  }
  
  .table-container th {
	background-color: #f4f4f4;
	font-weight: bold;
	text-transform: uppercase;
  }
  
  .table-container tr:nth-child(even) {
	background-color: #f9f9f9;
  }
  
  .table-container tr:hover {
	background-color: #f1f1f1;
  }
  
  .table-container th, .table-container td {
	text-align: center;
  }
  
  .table-title {
	text-align: center;
	font-size: 24px;
	margin-bottom: 20px;
	color: #333;
  }
  
  .note {
	text-align: center;
	font-style: italic;
	margin-top: 10px;
  }
  
  /* Flexbox container for the tables */
  .tables-container {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
  }
  
  .table-wrapper {
	flex: 1;
	margin: 20px;
	min-width: 300px;
	max-width: 50%;
  }
  
  @media (max-width: 768px) {
	.tables-container {
		margin: 1px !important; /* Update margin for mobile */
	}
  
	.table-wrapper .table-container {
	  margin: 1px !important; /* Update margin for mobile */
	}
  }
  
  .image-container {
	display: flex;
    align-items: flex-start;
    justify-content: center; /* Центрируем по горизонтали */
    overflow: hidden;
    padding: 10px;
    position: relative;
	gap: 100px;
  }
  
  .image-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
  }
  
  .custom-image {
	width: 300px;
	height: auto;
	transition: transform 0.3s ease;
  }
  
  .custom-image:hover {
	transform: scale(1.1);
  }
  
  .image-caption {
	margin-top: 5px;
	text-align: center;
	font-size: 22px;
	color: black !important;
	font-feature-settings: 'lnum' 1;
  }
  
  .text-block-tv {
	font-feature-settings: 'lnum' 1;
	
  }
  
  .text-block-tv p {
	color: black;
	font-size: 16px;
  }
  
  .old-price {
	text-decoration: line-through;
	font-weight: bold;
  }
  
  .new-price-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
  }
  
  .new-price {
	font-size: 24px;
	background-color: rgb(227, 52, 52);
	padding: 5px 10px;
	border-radius: 5px;
	font-weight: bold;
  }
  
  .ReviewsTV {
    margin-top: 40px;

}

.reviews-tv__header {
	font-family: "Raleway";
    text-align: center;
    margin-bottom: 22px;
	color: #0064cc;
	font-weight: bold;
	font-feature-settings: 'lnum' 1; /* Скачущие цифры */
}

.reviews-tv__slider {
    display: flex;
    justify-content: center;
    gap: 20px !important; /* Добавляем промежуток между отзывами */
}

.reviews-tv__review {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    width: 270px !important;
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 350px; /* Задаем фиксированную высоту для всех отзывов */
    margin: 0 !important;
	outline: none;

	display: flex !important;
}

.reviews-tv__author-wrapper {
    /* display: flex; */
    /* align-items: center; */
    margin-left: 25% !important;
}

/* .reviews-tv__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
} */

.reviews-tv__avatar_2 {
	width: 40px;
    height: auto;
	border-radius: 50%;
    position: absolute;
    /* top: 2px !important; */
    right: 70% !important;
}

.reviews-tv__author {
    font-weight: bold;
    margin-bottom: 5px;
}

.reviews-tv__date {
    font-style: italic;
    color: #666;
    font-size: 0.9em;
}

.reviews-tv__content {
    margin-top: 10px;
    flex-grow: 1; /* Занимает доступное пространство */
}

.reviews-tv__content p {
    font-size: 16px;
    margin: 0; /* Убираем отступы у параграфа */
	color: #000;
}

.reviews-tv__stars {
    color: #FFD700;
}

.reviews-tv__icon {
    position: absolute;
    top: 2px !important;
    right: -5% !important;
    width: 60px;
}


@media (max-width: 768px) {
	.reviews-tv__avatar_2 {
	  right: 80% !important;
	}
	.reviews-tv__icon {
	  right: 0% !important;
	}
	.reviews-tv__review {
	  margin: 0 auto !important;
	}
	/* .slick-dots{
		bottom: -50px !important;
	}
	.slick-dots li {
		margin: 0 5px !important;
	  } */
	  .slick-dots {
		display: none !important; /* Скрывает элемент, он не занимает места на странице */
	  }
  }

.image_article {
	height: 150px;
	margin-right: 10px;
	border-radius: 10px;
  }
  
  @media (max-width: 768px) {
	.image_article {
	  height: 100px;
	  width: 100px;
	}
  }


  .services-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	justify-content: center;
	margin-top: 20px;
  }
  
  .service-button {
	font-family: "Raleway";
	background-color: #1e90ff;
	color: #ffffff;
	padding: 15px 25px;
	text-align: center;
	text-decoration: none;
	border-radius: 5px;
	font-size: 16px;
	font-weight: bold;
	transition: background-color 0.3s ease;
	flex: 1 1 calc(16.333% - 20px); /* Adjust the width as needed */
  }
  
  .service-button:hover {
	background-color: #ff7f50;
  }
  

  @media (min-width: 768px) {
	.header-call-to-action-mobile {
	  display: none; /* Скрываем блок на десктопных устройствах (ширина экрана больше или равна 768px) */
	}
  }
  
  @media (max-width: 767px) { /* Стили только для мобильных устройств */
	body {
		background-color: #f0f0f0; /* Светло-серый общий фон */
	  }

	.mobile-container {
	  background-color: #ffffff; /* Белый цвет фона рамки */
	  padding: 10px; /* Внутренние отступы для создания пространства внутри рамки */
	  border-radius: 5px; /* Закругленные углы */
	  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень */
	  margin: 1px; /* Внешние отступы для видимости общего фона */
	  margin-bottom: 20px;
	}

	.mobile-container-home {
		background-color: #ffffff; /* Белый цвет фона рамки */
		padding: 10px; /* Внутренние отступы для создания пространства внутри рамки */
		border-radius: 5px; /* Закругленные углы */
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень */
		margin: 17px; /* Внешние отступы для видимости общего фона */
		margin-bottom: 20px;
	  }

	.header-call-to-action-1 {
		/* margin-left: 24px; */
		text-decoration: none;
		color: #0090ea;
		font-family: "Raleway";
		font-size: 25px;
		font-optical-sizing: auto;
		font-weight: 900;
  		font-style: normal;
		text-decoration: none; /* Remove underline from the text */
		text-transform: uppercase;
	  }

	  .header-call-to-action-2 {
		text-decoration: none;
		color: orange;
		font-family: "Raleway";
		font-size: 25px;
		font-optical-sizing: auto;
		font-weight: 900;
  		font-style: normal;
		text-decoration: none; /* Remove underline from the text */
		text-transform: uppercase;
	  }
  }
  
  .handyman-page {
	padding: 20px;
	display: flex; /* Добавляем flex для управления выравниванием */
	justify-content: center; /* Центрируем содержимое по горизонтали */
  }
  .handyman-services {
	display: flex;
	flex-wrap: wrap;
	gap: 20px; /* Интервал между плитками */
	justify-content: center; /* Центрируем плитки по горизонтали */
	max-width: 1200px; /* Максимальная ширина контейнера для плиток */
  }
  
  .handyman-service {
	width: 1100px; /* Ширина блока сервиса */
	background-color: #ffffff;
	border: 1px solid #dddddd;
	padding: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center; /* Центрирование элементов по вертикали */
	border-radius: 10px; /* Закругление углов */
  }
  
  .handyman-service-image {

	width: 150px; /* Ширина изображения */
	height: 150px;
	margin: 20px; /* Отступ между изображением и содержимым */
	margin-right: 40px;
	border-radius: 10px; /* Закругление углов изображения */
  }
  
  .handyman-service-content {
	flex: 1; /* Занимает все доступное пространство по ширине */
  }
  
  .handyman-service-title {
	margin-bottom: 10px;
	font-family: "Raleway";
	font-weight: bold;
	font-size: 22px;
	line-height: 1.2;
	color: #0064cc;
  }
  
  .handyman-service-description {
	font-family: "Raleway";
	color: grey;
	font-size: 19px;
	font-weight: 400;
	font-optical-sizing: auto;
	font-style: normal;
	margin-bottom: 30px;
  }
  
  .handyman-service-button {
	font-family: "Raleway";
	font-size: 20px;
	font-weight: bold;
	background-color: #ff6600;
	color: #ffffff;
	text-decoration: none;
	padding: 10px 20px;
	border-radius: 5px;
	transition: background-color 0.3s ease;
	text-align: center; /* Выравнивание текста по центру */
	border: none;
  }
  
  .handyman-service-button:hover {
	background-color: #e55500;
	cursor: pointer; /* Изменение курсора при наведении */
  }
  
  .handyman-sidebar {
	width: 300px; /* Ширина блока справа */
	margin-left: 20px; /* Отступ слева от плиток */
	padding: 20px;
	background-color: #f2f2f2;
	border: 1px solid #dddddd;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .handyman-sidebar h2 {
	font-size: 20px;
	color: #0064cc;
	margin-bottom: 15px;
  }
  
  .handyman-sidebar ol {
	list-style-type: decimal;
	margin-left: 20px;
	margin-bottom: 20px;
  }
  
  .handyman-sidebar li {
	margin-bottom: 15px;
  }
  
  .handyman-sidebar strong {
	font-weight: bold;
  }
  
  .testimonial {
	margin-bottom: 20px;
  }
  
  .testimonial p:first-child {
	font-weight: bold;
	margin-bottom: 5px;
  }

  .handyman_header_desc {
	font-family: "Raleway";
    text-align: justify;
    font-size: 19px;
    max-width: 1180px;
    line-height: 1.37;
    color: grey;
    margin: 0 auto;
    word-break: break-word; /* Добавленное свойство */
}

  .handyman_footer_desc {
	font-family: "Raleway";
	text-align: justify;
	font-size: 19px;
	max-width: 1200px;
	line-height: 1.37;
	color: grey;
	margin: 0 auto;
}

.handyman-slider li {
	list-style-type: disc; /* Устанавливаем маркер списка в виде кругов */
	margin-bottom: 10px; /* Отступ между элементами списка */
	line-height: 1.6; /* Межстрочный интервал для лучшей читаемости текста */
  }
  
  .handyman-slider li::before {
	font-size: 14px; /* Размер шрифта символа */
	margin-right: 8px; /* Отступ между символом и текстом элемента списка */
  }

@media (max-width: 768px) {
	/* .handyman-page {
		padding: 10px !important;
	  } */
	.handyman-services {
	  display: block; /* Располагаем блоки в столбик */
	}

	.handyman-reviews-slider{
		width: calc(33% - 20px);
	}
  
	.handyman-service {
	  width: calc(33% - 20px);
	  margin: 0 auto 20px; /* Центрируем блоки по горизонтали и добавляем отступ снизу */
	  background-color: #ffffff; /* Цвет фона блока */
	  border: 1px solid #dddddd; /* Граница блока */
	  padding: 20px; /* Внутренний отступ блока */
	  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень блока */
	  border-radius: 10px; /* Закругление углов блока */
	  overflow: hidden; /* Обрезаем содержимое, если оно выходит за границы */
	  display: flex; /* Отображаем содержимое блока в одну линию */
	  flex-direction: column; /* Располагаем содержимое блока вертикально */
	}
	.handyman-slider {
		width: calc(33% - 20px); /* Занимать всю доступную ширину */
		margin: 0 auto 20px; /* Центрируем блоки по горизонтали и добавляем отступ снизу */
		background-color: #ffffff; /* Цвет фона блока */
		border: 1px solid #dddddd; /* Граница блока */
		padding: 10px; /* Внутренний отступ блока */
		border-radius: 10px; /* Закругление углов блока */
		overflow: hidden; /* Обрезаем содержимое, если оно выходит за границы */
		display: flex; /* Отображаем содержимое блока в одну линию */
		flex-direction: column; /* Располагаем содержимое блока вертикально */
	  }
  
	.handyman-service-image-wrapper {
	  text-align: center; /* Центрируем изображение по горизонтали */
	  margin-bottom: 20px; /* Отступ снизу изображения */
	}
  
	.handyman-service-image {
	  width: 150px; /* Ширина изображения */
	  height: 150px; /* Высота изображения */
	  border-radius: 10px; /* Закругление углов изображения */
	}
  
	.handyman-service-content {
	  flex: 1; /* Занимаем все доступное пространство по ширине */
	  text-align: center; /* Центрируем текст по горизонтали */
	}
  
	.handyman-service-title {
	  margin-bottom: 10px; /* Отступ снизу заголовка */
	  font-family: "Raleway"; /* Шрифт заголовка */
	  font-weight: bold; /* Жирный шрифт */
	  font-size: 18px; /* Размер шрифта заголовка */
	  color: #0064cc; /* Цвет текста заголовка */
	}
  
	.handyman-service-description {
	  font-family: "Raleway"; /* Шрифт описания */
	  color: grey; /* Цвет текста описания */
	  font-size: 16px; /* Размер шрифта описания */
	  margin-bottom: 10px; /* Отступ снизу описания */
	}
  
	.handyman-service-button {
	  background-color: #ff6600; /* Цвет фона кнопки */
	  color: #ffffff; /* Цвет текста кнопки */
	  text-decoration: none; /* Убираем подчеркивание ссылки */
	  padding: 10px 20px; /* Внутренние отступы кнопки */
	  border-radius: 5px; /* Закругление углов кнопки */
	  transition: background-color 0.3s ease; /* Плавный переход цвета фона */
	  text-align: center; /* Выравнивание текста по центру */
	  display: inline-block; /* Блочный элемент */
	  border: none;
	}
  
	.handyman-service-button:hover {
	  background-color: #e55500; /* Цвет фона кнопки при наведении */
	  cursor: pointer; /* Изменение курсора при наведении */
	}
  }
  
  /* Стили для модального окна */
.ReactModal__Overlay {
		background-color: rgba(0, 0, 0, 0.75);
	z-index: 1000;
  }
  
  .ReactModal__Content {
	position: absolute;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	padding: 20px;
	border-radius: 10px;
	border: 1px solid #ddd;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	width: 90%;
	max-width: 400px;
  }




  .container_tv_handy {
	margin-bottom: 20px;
	overflow: hidden; /* Решает проблему с "всплыванием" float */
	}

	.container_tv_handy p {		
		font-family: "Raleway", sans-serif;
		color: grey;
		line-height: 1.36;
		padding-left: 10px;
		padding-right: 10px;
		text-align: justify;
		margin-bottom: 20px;
    }

	.container_tv_qoute_form {
		display: flex;
		flex-direction: column;
		max-width: 470px;
		width: 100%;
		height: auto;
		margin: 0 auto;
		padding: 0 20px;
		float:inline-end;
		margin-left: 40px;
		}		

	.container_tv_handy img {
	max-width: 300px;
	height: auto;
	float: left;
	margin-right: 20px; /* Добавляем отступ справа для разделения текста и изображения */
	}

	@media screen and (max-width: 767px) {
		.container_tv_handy {
			width: calc(33% - 20px);
			background-color: white; /* Цвет фона */
			border-radius: 15px; /* Радиус закругления углов */
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень */
			padding: 20px; /* Внутренние отступы */
			margin: 20px auto; /* Внешние отступы и центрирование */
			}
		.container_tv_handy img {
			float:none;
			display: block;
			margin: 0 auto !important;
			}
		.text h3 {
			margin-top: 20px !important;
			font-size: 19px !important;
		}

		.image-container {
			flex-direction: row !important; /* Горизонтальное размещение */
			gap: 20px !important;

		  }
		  
		  .custom-image {
			width: 150px !important; /* Фиксированная ширина изображения */
			height: auto; /* Автоматическая высота для пропорций */
		  }

		  .image-caption{
			font-size: 14px !important;
		  }

		  .new-price{
			font-size: 18px !important;
		  }

		  .text-block-tv p{
			font-size: 14px !important;
		  }

	}


	.handyman_container {
		display: flex;
		align-items: center; /* Выравнивание по вертикали */
	}

	@media (max-width: 768px) {
		.handyman_container {
			display:block; /* Используем flex-контейнер */
			align-items: center; /* Выравниваем дочерние элементы по вертикали */
			justify-content: center; /* Выравниваем дочерние элементы по горизонтали */
		}

	}

.tiles-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 0 auto;
  }
  
  .tile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 10px;
  }

  .tile p{
	margin-top: 15px;
	font-family: "Raleway"; /* Шрифт заголовка */
	font-weight: bold; /* Жирный шрифт */
	font-size: 18px; /* Размер шрифта заголовка */
	color: #0064cc; /* Цвет текста заголовка */
  }
  
  .tile img {
	max-width: 70%;
	max-height: 70%;
	justify-content: center;
	align-items: center;
	display: block; /* Удаление нижнего отступа, который могут добавлять inline элементы */
	margin: auto; /* Центрирование изображения в контейнере */
	border-radius: 15px; /* Закругление краев */
	object-fit: contain; /* Центрирование изображения внутри контейнера */
  }




  .image-container-earn {
	display: none;
  }



@media (max-width: 768px) {

	.image-container-earn {
		display: block;
	  }

	.image-container-earn {
		position: relative;
		display: inline-block;
	}

	.centered-text-earn {
		font-family: "Raleway";
		position: absolute;
		top: 25%;
		color: #0090ea;
		font-size: 45px; /* Большой размер для привлечения внимания */
		font-weight: 900; /* Жирный текст для четкости */
		text-shadow: 3px 3px 6px rgba(38, 28, 28, 0.7);
		text-align:center ; /* Выравнивание по центру для длинных текстов */
	}

	.rounded-image-earn {
		border-radius: 25px;
		opacity: 0.8;
		width: 100%;
		height: auto;
	}

}

.slick-prev-new:before, .slick-next-new:before {
	font-family: 'slick';
	font-size: 80px !important;
	color: #ff6900 !important;
  }

/* Контейнер для адаптивного дизайна */
.responsive-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row; /* Картинка слева от текста по умолчанию */
}

@media (max-width: 768px) {
	.responsive-container {
		flex-direction: column; /* Картинка сверху, текст снизу для мобильных */
	}
}

/* Стили изображения */
.responsive-image {
	border-radius: 50%;
	width: 200px; /* Размер для полной версии */
	height: 200px;
	margin-right: 20px; /* Отступ справа */
	object-fit: cover;
}

@media (max-width: 768px) {
	.responsive-image {
		width: 200px; /* Уменьшенный размер для мобильной версии */
		height: 200px;
		margin-right: 0;
		margin-bottom: 20px; /* Отступ снизу вместо справа */
	}
}

/* Стили текста */
.responsive-text {
	max-width: 500px;
	text-align: left; /* Выравнивание текста для полной версии */
}

@media (max-width: 768px) {
	.responsive-text {
		text-align: center; /* Центрирование текста для мобильной версии */
	}
}


/* Стили для кнопки "Подробнее" */
.repair-steps-container {
	max-width: 900px;
	margin: 0 auto;
	padding: 20px;
	text-align: center;
}

.repair-title {
	/*font-size: 24px;*/
	font-weight: bold;
	margin-bottom: 20px;
}

.repair-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	justify-content: center;
}

.repair-card {
	background: white;
	padding: 20px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	text-align: center;
	width: 320px;
	height: 260px;
}

.repair-icon {
	font-size: 60px;
	color: #ff6900;
	margin-bottom: 10px;
}

.repair-step-title {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 5px;
}

.repair-description {
	font-size: 18px;
	color: #666;
}

.repair-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
}

@media (max-width: 768px) {
	.repair-grid {
		grid-template-columns: repeat(1, 1fr);
	}
}
